import React, { Component, useState } from 'react'
// import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";
import { color } from '../../sass-files/variable';
import Modal from '../Modal';
import LoginContent from '../login-signup/login/login-content';
// import Modal from '../../Modal';
import SlickSliderGames from '../slick-slider/slick-sliderGameLoading';
import cookieUtil from 'src/util/cookieUtil';
import { ECookieName } from 'src/util/utilModel';
import { useNavigate } from "react-router-dom";
import config from 'src/config/config';
import Intl from '../common/Intl'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { ifProp, theme, withProp } from 'styled-tools';
import styled, { withTheme } from 'styled-components';
const Andarbahar = `${'static/Image/andarbahar.jpg'}`
const Andarbahar1 = `${'static/Image/andar-bahar-ezuzi_laptopnew.jpg'}`
// const Andarbahar2 = `${'static/Image/andarbahar2.jpg'}`
// const AmarAkbarAnthony = `${'static/Image/amar_akbar_anthony.jpg'}`
// const LiveCasinoMain = `${'static/Image/'}`
const DragonTiger = `${'static/Image/dragon_tiger.jpg'}`
// const DragonTiger1 = `${'static/Image/dragon_tiger1.jpg'}`
// const DragonTiger2 = `${'static/Image/dragontiger2.jpg'}`
// const DragonTiger3 = `${'static/Image/dragontiger3.jpg'}`
// const DragonTiger4 = `${'static/Image/dragontiger4.jpg'}`
// const DragonTiger5 = `${'static/Image/dragontiger5.jpg'}`
const DreamCatcher = `${'static/Image/dream_catcher.jpg'}`
const DiceDual = `${'static/Image/dice_laptop.jpg'}`
const Dicedual = `${'static/Image/dice_dual_laptop.jpg'}`
// const CardCasino = `${'static/Image/32cardcasino.jpg'}`
// const CardCasino1 = `${'static/Image/32cardcasino1.jpg'}`
const FootballStudio = `${'static/Image/footballstudio.jpg'}`
// const Roullette = `${'static/Image/roullette.jpg'}`
const Roulette1 = `${'static/Image/roulette1.jpg'}`
const Roulette2 = `${'static/Image/roulette2.jpg'}`
// const Roulette3 = `${'static/Image/roulette3.jpg'}`
// const Roulette4 = `${'static/Image/roulette4.jpg'}`
// const Roulette5 = `${'static/Image/roulette5.jpg'}`
const BlackJack = `${'static/Image/black_jack.jpg'}`
// const Blackjack1 = `${'static/Image/blackjack1.jpg'}`
// const Blackjack2 = `${'static/Image/blackjack2.jpg'}`
// const SicBo = `${'static/Image/sicbo.jpg'}`
// const SicBo1 = `${'static/Image/sicbo1.jpg'}`
// const SicBo2 = `${'static/Image/sicbo2.jpg'}`
const SideBetCity = `${'static/Image/sidebetcity.jpg'}`
// const SuperSpadeRoulette = `${'static/Image/superspaderoulette.jpg'}`
const Baccarat = `${'static/Image/baccarat.jpg'}`
// const Baccarat1 = `${'static/Image/baccarat1.jpg'}`
const Baccarat2 = `${'static/Image/baccarat2.jpg'}`
// const Baccarat3 = `${'static/Image/baccarat3.jpg'}`
// const Baccarat4 = `${'static/Image/baccarat4.jpg'}`
// const Baccarat5 = `${'static/Image/baccarat5.jpg'}`
// const Baccarat6 = `${'static/Image/baccarat6.jpg'}`
// const Baccarat7 = `${'static/Image/baccarat7.jpg'}`
// const Baccarat8 = `${'static/Image/baccarat8.jpg'}`
// const Baccarat9 = `${'static/Image/baccarat9.jpg'}`
const Poker1 = `${'static/Image/poker_laptop.jpg'}`
const Poker2 = `${'static/Image/bet_on_poker_laptop.jpg'}`
const Poker3 = `${'static/Image/sixpoker_laptop.jpg'}`
const Poker4 = `${'static/Image/speedy-7_laptop.jpg'}`
const Poker5 = `${'static/Image/poker5.jpg'}`
// const HiLo = `${'static/Image/hi_lo.jpg'}`
const LightningDice = `${'static/Image/lightningdice.jpg'}`
const Lucky7 = `${'static/Image/lucky7.jpg'}`
const Lucky6 = `${'static/Image/lucky6.jpg'}`
const Lucky5 = `${'static/Image/lucky5.jpg'}`
// const SpinAWin = `${'static/Image/spin_a_win.jpg'}`
const WarofBets1 = `${'static/Image/war_of_bets_1.jpg'}`
// const Wheel = `${'static/Image/wheel.jpg'}`
// const TeenPatti = `${'static/Image/live_casino_main.png'}`
const TeenPatti1 = `${'static/Image/teen_patti.jpg'}`
// const TeenPatti2 = `${'static/Image/teenpatti1.jpg'}`
// const TeenPatti3 = `${'static/Image/teenpatti2.jpg'}`
// const TeenPatti4 = `${'static/Image/teenpatti3.jpg'}`
// const TeenPatti5 = `${'static/Image/teenpatti4.jpg'}`
// const UpDown = `${'static/Image/7up&down.jpg'}`
const UpDown1 = `${'static/Image/7up&down1.jpg'}`
// const LotteryCasinobg = `${'static/Image/lottery-casinobg.png'}`
const baccaratlaptop = `${'static/Image/baccarat-laptop.jpg'}`
const baccarat2laptop = `${'static/Image/baccarat2-laptop.jpg'}`
const roulettelaptop = `${'static/Image/roulettelaptopnew.jpg'}`
const cards32laptop = `${'static/Image/32cardslaptopnew.jpg'}`
const betonnumberlaptop = `${'static/Image/betonnumberlaptopnew.jpg'}`
const baccarattlaptop = `${'static/Image/baccaratlaptopnew.jpg'}`
const dragontigertablelaptop = `${'static/Image/dragontigertablelaptopnew.jpg'}`
const teenpattilaptop = `${'static/Image/teenpattilaptopnew.jpg'}`
const lucky7laptop = `${'static/Image/lucky7laptopnew.jpg'}`
const blackjacklaptop = `${'static/Image/blackjacklaptopnew.jpg'}`

// const VivoBaccaratlaptop = `${'static/Image/Vivo-Baccarat_laptop.jpg'}`
// const VivoBlackjacklaptop = `${'static/Image/Vivo-Blackjack_laptop.jpg'}`
// const ClassicRoulettelaptop = `${'static/Image/Classic-Roulette_laptop.jpg'}`
// const VIPBlackjacklaptop = `${'static/Image/VIP-Blackjack_laptop.jpg'}`
// const SpanishRoulettelaptop = `${'static/Image/Spanish-Roulette_laptop.jpg'}`
// const VIPBaccaratlaptop = `${'static/Image/VIP-Baccarat_laptop.jpg'}`
// const ClassicBaccaratlaptop = `${'static/Image/Classic-Baccarat_laptop.jpg'}`
// const UruguayEuropeanRoulettelaptop = `${'static/Image/Uruguay-European-Roulette_laptop.jpg'}`
// const MacauRoulettelaptop = `${'static/Image/Macau-Roulette_laptop.jpg'}`
// const OrientalBaccaratlaptop = `${'static/Image/Oriental-Baccarat_laptop.jpg'}`
// const MacauBaccaratlaptop = `${'static/Image/Macau-Baccarat_laptop.jpg'}`
// const AmericanPortugueseRoulettelaptop = `${'static/Image/American-Portuguese-Roulette_laptop.jpg'}`
// const PortomasoCasinolaptop = `${'static/Image/Portomaso-Casino_laptop.jpg'}`
// const AutoRoulettelaptop = `${'static/Image/Auto-Roulette_laptop.jpg'}`
// const OracleRoulettelaptop = `${'static/Image/Oracle-Roulette_laptop.jpg'}`
// const Oracle360Roulettelaptop = `${'static/Image/Oracle-360-Roulette_laptop.jpg'}`
// const CasinoHoldemlaptop = `${'static/Image/Casino-Holdem_laptop.jpg'}`
// const VivoAladdinBaccaratlaptop = `${'static/Image/Vivo-Aladdin-Baccarat_laptop.jpg'}`

const bacaratlaptop = `${'static/Image/bacarat_laptop.jpg'}`
const blackjacknetent = `${'static/Image/blackjack-netent_laptop.jpg'}`

const kenolaptop = `${'static/Image/keno_laptop.jpg'}`
const jokerbetlaptop = `${'static/Image/joker-bet_laptop.jpg'}`
const sevenbetlaptop = `${'static/Image/seven_bet_laptop.jpg'}`
const fivebettvbetlaptop = `${'static/Image/5-bet-tvbet_laptop.jpg'}`
const twenty1bettvbetlaptop = `${'static/Image/21-bet--tvbet_laptop.jpg'}`
const pokertvbetlaptop = `${'static/Image/poker-tvbet_laptop.jpg'}`
const WHEELBETtvbetlaptop = `${'static/Image/WHEELBET_tvbet_laptop.jpg'}`
const warofelemntstvbetlaptop = `${'static/Image/war-of-elemnts-tvbet_laptop.jpg'}`
const onebetlBackgammontvbetlaptopaptop = `${'static/Image/Backgammon-tvbet_laptop.jpg'}`
const LUCKY6tvbetlaptop = `${'static/Image/LUCKY6_tvbet_laptop.jpg'}`
const FRUITRACEtvbetlaptop = `${'static/Image/FRUITRACE-tvbet_laptop.jpg'}`
const onebetlaptop = `${'static/Image/one-bet_laptop.jpg'}`

// BETSOFT 

const baccaratBSlaptop = `${'static/Image/baccaratBSlaptop.png'}`
const ZOOMROULETTElaptop = `${'static/Image/ZOOM-ROULETTE_betsoft.jpg'}`
const BlackjackBSlaptop = `${'static/Image/square_btn_european_blackjack.jpg'}`
// const takeolympuslaptop = `${'static/Image/take-olympus_laptop.jpg'}`
// const MonsterPoplaptop = `${'static/Image/Monster-Pop_laptop.jpg'}`
// const backtovenuslaptop = `${'static/Image/back-to-venus_laptop.jpg'}`
// const FRUITZENLAPTOP = `${'static/Image/FRUIT-ZEN_LAPTOP.jpg'}`
// const sugarpoplaptop = `${'static/Image/sugar-pop_laptop.jpg'}`
const OASISPOKERlaptop = `${'static/Image/square_btn_oasis_poker.png'}`

// JACKPOT

// const smalljackpot = `${'static/Image/JACKPOTSMALL.png'}`

// TEENPATTIPLUS

const teenpattiPlus1 = `${'static/Image/tennpatti2020.png'}`
const andaarbahar = `${'static/Image/andarbahar11.png'}`
const teenpattiplus2 = `${'static/Image/tenpattiplusnew.png'}`

function FlagsContainer(props) {
    const { theme } = props
    const { config: { 
        showDBPromoLink=true, 
        showDBProfileLink=true,
        showDBFinanceLink=true,
        showDBBetDetailLink=true
    } } = theme;
    let navigate: any = useNavigate();


    const [modelState, setModelState] = useState(false)


    const CasinoStatementShowModal = () => {
        (cookieUtil.get(ECookieName.COOKIE_TOKEN)) ? navigate('/casinostatement')
            : setModelState(true);
    };

    const StatementShowModal = () => {
        (cookieUtil.get(ECookieName.COOKIE_TOKEN)) ? navigate('/accountstatement')
            : setModelState(true);
    };

    const MyBetShowModal = () => {
        (cookieUtil.get(ECookieName.COOKIE_TOKEN)) ? navigate('/myorder')
            : setModelState(true);
    };

    const hideModal = () => {
        setModelState(false);
    };

    const emitHideModel = (flag) => {
        setModelState(flag)
    };


    const casinoGameList = [
        {
            id: 1,
            type: 'EVOLUTION',
            game: "baccarat",
            // url: 'http://google.com',
            platformCode: "WACS",
            code: "EVOLUTION-baccarat-lobby",
            imgsrc: Baccarat
        },
        {
            id: 2,
            type: 'EVOLUTION',
            game: "black_jack",
            // url: 'http://google.com',
            imgsrc: BlackJack,
            platformCode: "WACS",
            code: "EVOLUTION-blackjack-lobby",
        },
        {
            id: 3,
            type: 'EVOLUTION',
            game: "dream_catcher",
            // url: 'http://google.com',
            imgsrc: DreamCatcher,
            platformCode: "WACS",
            code: window.innerWidth > 576 ? "EVOLUTION-moneywheel-MOWDream00000001" : "EVOLUTION-moneywheel-nvrpo3bv6teqkuco",
        },
        {
            id: 4,
            type: 'EVOLUTION',
            game: "footballstudio",
            // url: 'http://google.com',
            imgsrc: FootballStudio,
            platformCode: "WACS",
            code: "EVOLUTION-rng~topcard-rng~topcard00001",
        },
        {
            id: 5,
            type: 'EVOLUTION',
            game: "lightningdice",
            // url: 'http://google.com',
            imgsrc: LightningDice,
            platformCode: "WACS",
            code: "EVOLUTION-roulette-LightningTable01",
        },
        {
            id: 6,
            type: 'EVOLUTION',
            game: "poker",
            // url: 'http://google.com',
            imgsrc: Poker1,
            platformCode: "WACS",
            code: "EVOLUTION-poker-lobby",
        },
        {
            id: 7,
            type: 'EVOLUTION',
            game: "Roulette",
            // url: 'http://google.com',
            imgsrc: Roulette1,
            platformCode: "WACS",
            code: "EVOLUTION-roulette-lobby",
        },
        {
            id: 8,
            type: 'EVOLUTION',
            game: "sidebetcity",
            // url: 'http://google.com',
            imgsrc: SideBetCity,
            platformCode: "WACS",
            code: "EVOLUTION-sidebetcity-SBCTable00000001",
        },
        {
            id: 9,
            type: 'SUPERSPADE',
            game: "andar bahar-laptop",
            // url: 'http://google.com',
            imgsrc: Andarbahar,
            platformCode: "WACS",
            code: "Ab1lkajgh7trgh20",
        },
        {
            id: 10,
            type: 'SUPERSPADE',
            game: "dragon tiger-laptop",
            // url: 'http://google.com',
            imgsrc: DragonTiger,
            platformCode: "WACS",
            code: "Dt19er0d1t2iew37",
        },
        {
            id: 11,
            type: 'SUPERSPADE',
            game: "roulette-laptop",
            // url: 'http://google.com',
            imgsrc: Roulette2,
            platformCode: "WACS",
            code: "Rou1hq4jo2etrjg3",
        },
        {
            id: 12,
            type: 'SUPERSPADE',
            game: "teen_patti-laptop",
            // url: 'http://google.com',
            imgsrc: TeenPatti1,
            platformCode: "WACS",
            code: "Tnptikajgh8t2020",
        },
        {
            id: 13,
            type: 'SUPERSPADE',
            game: "baccarat-laptop",
            // url: 'http://google.com',
            imgsrc: baccaratlaptop,
            platformCode: "WACS",
            code: "Bacc5a7r5to2etrl",
        },
        {
            id: 14,
            type: 'SUPERSPADE',
            game: "baccarat2-laptop",
            // url: 'http://google.com',
            imgsrc: baccarat2laptop,
            platformCode: "WACS",
            code: "Baccara2786tblew",
        },
        {
            id: 15,
            type: 'BETGAMES',
            game: "baccarat_laptop",
            // url: 'http://google.com',
            imgsrc: Baccarat2,
            platformCode: "WACS",
            code: "BGAMES-6",
        },
        {
            id: 16,
            type: 'BETGAMES',
            game: "bet on poker_laptop",
            // url: 'http://google.com',
            imgsrc: Poker2,
            platformCode: "WACS",
            code: "BGAMES-5",
        },
        {
            id: 17,
            type: 'BETGAMES',
            game: "dice dual_laptop",
            // url: 'http://google.com',
            imgsrc: DiceDual,
            platformCode: "WACS",
            code: "BGAMES-10",
        },
        {
            id: 18,
            type: 'BETGAMES',
            game: "dice _laptop",
            // url: 'http://google.com',
            imgsrc: Dicedual,
            platformCode: "WACS",
            code: "BGAMES-4",
        },
        {
            id: 19,
            type: 'BETGAMES',
            game: "Lucky 5_laptop",
            // url: 'http://google.com',
            imgsrc: Lucky5,
            platformCode: "WACS",
            code: "BGAMES-3",
        },
        {
            id: 20,
            type: 'BETGAMES',
            game: "Lucky 6_laptop",
            // url: 'http://google.com',
            imgsrc: Lucky6,
            platformCode: "WACS",
            code: "BGAMES-9",
        },
        {
            id: 21,
            type: 'BETGAMES',
            game: "Lucky 7_laptop",
            // url: 'http://google.com',
            imgsrc: Lucky7,
            platformCode: "WACS",
            code: "BGAMES-1",
        },
        {
            id: 22,
            type: 'BETGAMES',
            game: "sixpoker_laptop",
            // url: 'http://google.com',
            imgsrc: Poker3,
            platformCode: "WACS",
            code: "BGAMES-12",
        },
        {
            id: 23,
            type: 'BETGAMES',
            game: "speedy-7_laptop",
            // url: 'http://google.com',
            imgsrc: Poker4,
            platformCode: "WACS",
            code: "BGAMES-11",
        },
        {
            id: 24,
            type: 'BETGAMES',
            game: "war_of_bets_1_laptop",
            // url: 'http://google.com',
            imgsrc: WarofBets1,
            platformCode: "WACS",
            code: "BGAMES-8",
        },
        {
            id: 25,
            type: 'BETGAMES',
            game: "wheel of fortune_laptop",
            // url: 'http://google.com',
            imgsrc: UpDown1,
            platformCode: "WACS",
            code: "BGAMES-7",
        },
        {
            id: 26,
            type: 'EZUGI',
            game: "andar-bahar-ezuzi",
            // url: 'http://google.com',
            imgsrc: Andarbahar1,
            platformCode: "WACS",
            code: "EZUGI-AndarBahar_228000",
        },
        {
            id: 27,
            type: 'EZUGI',
            game: "blackjack_laptop",
            // url: 'http://google.com',
            imgsrc: blackjacklaptop,
            platformCode: "WACS",
            code: "EZUGI-lobby",
        },
        {
            id: 28,
            type: 'EZUGI',
            game: "roulette_laptop",
            // url: 'http://google.com',
            imgsrc: roulettelaptop,
            platformCode: "WACS",
            code: "EZUGI-lobby",
        },
        {
            id: 29,
            type: 'EZUGI',
            game: "32-cards_laptop",
            // url: 'http://google.com',
            imgsrc: cards32laptop,
            platformCode: "WACS",
            code: "EZUGI-32cards_228002",
        },
        {
            id: 30,
            type: 'EZUGI',
            game: "bet-on-number_laptop",
            // url: 'http://google.com',
            imgsrc: betonnumberlaptop,
            platformCode: "WACS",
            code: "EZUGI-Lottery_602000",
        },
        {
            id: 31,
            type: 'EZUGI',
            game: "baccarat_laptop",
            // url: 'http://google.com',
            imgsrc: baccarattlaptop,
            platformCode: "WACS",
            code: "EZUGI-lobby",
        },
        {
            id: 32,
            type: 'EZUGI',
            game: "dragon-tiger-table_laptop",
            // url: 'http://google.com',
            imgsrc: dragontigertablelaptop,
            platformCode: "WACS",
            code: "EZUGI-BaccaratDragonTiger_150",
        },
        {
            id: 33,
            type: 'EZUGI',
            game: "teenpatti_laptop",
            // url: 'http://google.com',
            imgsrc: teenpattilaptop,
            platformCode: "WACS",
            code: "EZUGI-ThreeCardPoker_227100",
        },
        {
            id: 34,
            type: 'EZUGI',
            game: "lucky-7_laptop",
            // url: 'http://google.com',
            imgsrc: lucky7laptop,
            platformCode: "WACS",
            code: "EZUGI-lucky7_228001",
        },
        {
            id: 35,
            type: 'NETENT',
            game: "poker_laptop",
            // url: 'http://google.com',
            imgsrc: Poker5,
            platformCode: "WACS",
            code: "NETENT-casinoholdem_not_mobile_sw",
        },
        {
            id: 36,
            type: 'NETENT',
            game: "bacarat_laptop",
            // url: 'http://google.com',
            imgsrc: bacaratlaptop,
            platformCode: "WACS",
            code: "NETENT-casinoholdem_not_mobile_sw",
        },
        {
            id: 37,
            type: 'NETENT',
            game: "blackjack-netent_laptop",
            // url: 'http://google.com',
            imgsrc: blackjacknetent,
            platformCode: "WACS",
            code: "NETENT-casinoholdem_not_mobile_sw",
        },
        {
            id: 38,
            type: 'TV BET',
            game: "1Bet",
            // url: 'http://google.com',
            imgsrc: onebetlaptop,
            platformCode: "WACS",
            code: "TVBET-10",
        },
        {
            id: 39,
            type: 'TV BET',
            game: "Keno",
            // url: 'http://google.com',
            imgsrc: kenolaptop,
            platformCode: "WACS",
            code: "TVBET-9",
        },
        {
            id: 40,
            type: 'TV BET',
            game: "Jokerbet",
            // url: 'http://google.com',
            imgsrc: jokerbetlaptop,
            platformCode: "WACS",
            code: "TVBET-8",
        },
        {
            id: 41,
            type: 'TV BET',
            game: "7Bet",
            // url: 'http://google.com',
            imgsrc: sevenbetlaptop,
            platformCode: "WACS",
            code: "TVBET-7",
        },
        {
            id: 42,
            type: 'TV BET',
            game: "5Bet",
            // url: 'http://google.com',
            imgsrc: fivebettvbetlaptop,
            platformCode: "WACS",
            code: "TVBET-6",
        },
        {
            id: 43,
            type: 'TV BET',
            game: "21Bet",
            // url: 'http://google.com',
            imgsrc: twenty1bettvbetlaptop,
            platformCode: "WACS",
            code: "TVBET-4",
        },
        {
            id: 44,
            type: 'TV BET',
            game: "poker",
            // url: 'http://google.com',
            imgsrc: pokertvbetlaptop,
            platformCode: "WACS",
            code: "TTVBET-3",
        },
        {
            id: 45,
            type: 'TV BET',
            game: "Wheelbet",
            // url: 'http://google.com',
            imgsrc: WHEELBETtvbetlaptop,
            platformCode: "WACS",
            code: "TVBET-2",
        },
        {
            id: 46,
            type: 'TV BET',
            game: "WAR OF ELEMENTS",
            // url: 'http://google.com',
            imgsrc: warofelemntstvbetlaptop,
            platformCode: "WACS",
            code: "TVBET-5",
        },
        {
            id: 47,
            type: 'TV BET',
            game: "Backgammon",
            // url: 'http://google.com',
            imgsrc: onebetlBackgammontvbetlaptopaptop,
            platformCode: "WACS",
            code: "TVBET-1",
        },
        {
            id: 48,
            type: 'TV BET',
            game: "Lucky6",
            // url: 'http://google.com',
            imgsrc: LUCKY6tvbetlaptop,
            platformCode: "WACS",
            code: "TVBET-12",
        },
        {
            id: 49,
            type: 'TV BET',
            game: "FRUITRACE",
            // url: 'http://google.com',
            imgsrc: FRUITRACEtvbetlaptop,
            platformCode: "WACS",
            code: "TVBET-14",
        },
        {
            id: 50,
            type: 'BETSOFT',
            game: "BACCARAT",
            // url: 'http://google.com',
            imgsrc: baccaratBSlaptop,
            platformCode: "WACS",
            code: "BETSOFT-13",
        },
        {
            id: 51,
            type: 'BETSOFT',
            game: "ZOOM-ROULETTE",
            // url: 'http://google.com',
            imgsrc: ZOOMROULETTElaptop,
            platformCode: "WACS",
            code: "BETSOFT-278",
        },
        {
            id: 52,
            type: 'BETSOFT',
            game: "EUROPEAN BLACKJACK",
            // url: 'http://google.com',
            imgsrc: BlackjackBSlaptop,
            platformCode: "WACS",
            code: "BETSOFT-191",
        },
        {
            id: 58,
            type: 'BETSOFT',
            game: "OASIS POKER",
            // url: 'http://google.com',
            imgsrc: OASISPOKERlaptop,
            platformCode: "WACS",
            code: "BETSOFT-203",
        },
        // {
        //     id: 59,
        //     type: 'JACKPOT',
        //     game: "JACKPOT",
        //     // url: 'http://google.com',
        //     imgsrc: smalljackpot,
        //     platformCode: "SLottery",
        //     code: "SLottery",
        // },
        {
            id: 60,
            type: 'TEENPATTIPLUS',
            game: "TEENPATTIPLUS2020",
            // url: 'http://google.com',
            imgsrc: teenpattiPlus1,
            platformCode: "AgTeenpatti",
            code: "",
        },

        {
            id: 61,
            type: 'TEENPATTIPLUS',
            game: "TEENPATTIPLUS",
            // url: 'http://google.com',
            imgsrc: teenpattiplus2,
            platformCode: "AgTeenpatti",
            code: "",
        },
        {
            id: 62,
            type: 'TEENPATTIPLUS',
            game: "ANDARBAHAR",
            // url: 'http://google.com',
            imgsrc: andaarbahar,
            platformCode: "AgTeenpatti",
            code: "",
        },
    ];

    const [modal, setModal] = useState(false);
    const handleClose = () => setModal(false);

    const toggleModal = (gameName) => {
        setSelectedGame([gameName])
        setModal(!modal)



    }

    const [selectedGame, setSelectedGame] = useState([]);


    casinoGameList.map((item, index) => {
        <p>{item.game}</p>
    })


    return (
        <>
            <Wrapper3>
                <h3><Intl langKey="TITLE@COME_PLAY_RULE" /></h3>
            </Wrapper3>
            <LazyLoadComponent>
            <FlagsWrapper >
                <FlagSection className='gameflags'>
                    <div className="flag1">
                        <span className="card">
                            <h4><Intl langKey="TITLE@SPORTS" /></h4>
                            {/* <div className="one"><img src={`${config.imageS3Host}/static/snk/${theme.image.new_whitelable_footer_number_1}`}  alt="one_flag"/><Link to={{ pathname: '/sports' }}><Intl langKey="TITLE@EXCHANGE" /></Link></div> */}
                            <div className="one"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_1}`} alt="one_flag" /><Link to={{ pathname: '/betting-exchange' }}><Intl langKey="TITLE@EXCHANGE" /></Link></div>
                            <div className="one noBg"><Link to={{ pathname: '/betting-exchange/online-cricket-betting' }}><Intl langKey="SPORT@CRICKET" /></Link></div>
                            <div className="one noBg"><Link to={{ pathname: '/betting-exchange/football-betting' }}><Intl langKey="SPORT@FOOTBALL" /></Link></div>
                            <div className="one noBg"><Link to={{ pathname: '/betting-exchange/tennis-betting' }}><Intl langKey="SPORT@TENNIS" /></Link></div>
                            <div className="one noBg"><Link to={{ pathname: '/betting-exchange/volleyball-betting' }}><Intl langKey="SPORT@VolleyBall" /></Link></div>
                            {/* <div className="one"><img src="/static/Image/png/2.png" /><a href="#">Fixed Odds</a></div> */}
                            <div className="one"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_2}`} alt="one_flag" /><Link to={{ pathname: '/sportsbook' }}><Intl langKey="MENU@SPORTBOOK" /></Link></div>
                        </span>
                    </div>
                    <div className="flag1">
                        <span className="card">
                            <h4><Intl langKey="TITLE@TOP" /> <br /> <Intl langKey="TITLE@CASINO" /></h4>
                            {/* <div className="one"><img src={`${config.imageS3Host}/static/snk/${theme.image.new_whitelable_footer_number_1}`} /><a onClick={() =>toggleModal(casinoGameList[1])}><Intl langKey="TITLE@BLACKJACK" /></a></div>
                        <div className="one"><img src={`${config.imageS3Host}/static/snk/${theme.image.new_whitelable_footer_number_2}`} /><a onClick={() =>toggleModal(casinoGameList[6])}><Intl langKey="TITLE@ROULETTE" /></a></div>
                        <div className="one"><img src={`${config.imageS3Host}/static/snk/${theme.image.new_whitelable_footer_number_3}`} /><a onClick={() =>toggleModal(casinoGameList[0])}><Intl langKey="TITLE@BACCARAT" /></a></div>
                        <div className="one"><img src={`${config.imageS3Host}/static/snk/${theme.image.new_whitelable_footer_number_4}`} /><a onClick={() =>toggleModal(casinoGameList[10])}><Intl langKey="TITLE@DRAGON_TIGER" /></a></div>
                        <div className="one"><img src={`${config.imageS3Host}/static/snk/${theme.image.new_whitelable_footer_number_5}`} /><a onClick={() =>toggleModal(casinoGameList[7])}><Intl langKey="TITLE@SIDE_BET_CITY" /></a></div> */}

                            <div className="one noBg"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_1}`} alt="BLACKJACK" /><a onClick={() => toggleModal(casinoGameList[1])}><Intl langKey="TITLE@BLACKJACK" /></a></div>
                            <div className="one noBg"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_2}`} alt="ROULETTE" /><a onClick={() => toggleModal(casinoGameList[6])}><Intl langKey="TITLE@ROULETTE" /></a></div>
                            <div className="one noBg"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_3}`} alt="BACCARAT" /><a onClick={() => toggleModal(casinoGameList[0])}><Intl langKey="TITLE@BACCARAT" /></a></div>
                            <div className="one noBg"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_4}`} alt="DRAGON_TIGER" /><a onClick={() => toggleModal(casinoGameList[10])}><Intl langKey="TITLE@DRAGON_TIGER" /></a></div>
                            <div className="one noBg"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_5}`} alt="SIDE_BET_CITY" /><a onClick={() => toggleModal(casinoGameList[7])}><Intl langKey="TITLE@SIDE_BET_CITY" /></a></div>
                        </span>
                    </div>
                    <div className="flag1">
                        <span className="card">
                            <h4><Intl langKey="TITLE@INDIAN_GAMES" /></h4>
                            {/* <div className="one"><img src={`${config.imageS3Host}/static/snk/${theme.image.new_whitelable_footer_number_1}`} /><a onClick={() =>toggleModal(casinoGameList[32])}><Intl langKey="TITLE@TEENPATTI" /></a></div>
                        <div className="one"><img src={`${config.imageS3Host}/static/snk/${theme.image.new_whitelable_footer_number_2}`} /><a onClick={() =>toggleModal(casinoGameList[8])}><Intl langKey="TITLE@ANDAR_BAHAR" /></a></div>
                        <div className="one"><img src={`${config.imageS3Host}/static/snk/${theme.image.new_whitelable_footer_number_3}`} /><a onClick={() =>toggleModal(casinoGameList[24])}><Intl langKey="TITLE@7UP" /></a></div>
                        <div className="one"><img src={`${config.imageS3Host}/static/snk/${theme.image.new_whitelable_footer_number_4}`} /><a onClick={() =>toggleModal(casinoGameList[16])}><Intl langKey="TITLE@DICE_GAMES" /></a></div> */}

                            <div className="one noBg"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_1}`} alt="TEENPATTI" /><a onClick={() => toggleModal(casinoGameList[32])}><Intl langKey="TITLE@TEENPATTI" /></a></div>
                            <div className="one noBg"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_2}`} alt="ANDAR_BAHAR" /><a onClick={() => toggleModal(casinoGameList[8])}><Intl langKey="TITLE@ANDAR_BAHAR" /></a></div>
                            <div className="one noBg"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_3}`} alt="TITLE@7UP" /><a onClick={() => toggleModal(casinoGameList[24])}><Intl langKey="TITLE@7UP" /></a></div>
                            <div className="one noBg"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_4}`} alt="DICE_GAMES" /><a onClick={() => toggleModal(casinoGameList[16])}><Intl langKey="TITLE@DICE_GAMES" /></a></div>

                        </span>
                    </div>
                    <div className="flag1">
                        <span className="card">
                            <h4><Intl langKey="TITLE@SPECIALS" /></h4>
                            <div className="one"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_1}`} alt="VIRTUALSPORTS" /><Link to={{ pathname: '/virtualSports/vfb' }}><Intl langKey="TITLE@VIRTUALSPORTS" /></Link></div>
                            <div className="one noBg"><Link to={{ pathname: '/virtualSports/vci' }}><Intl langKey="VIRTUALSPORTSMENUTITLE@CRICKET" /></Link></div>
                            <div className="one noBg"><Link to={{ pathname: '/virtualSports/vfb' }}><Intl langKey="VIRTUALSPORTSMENUTITLE@WC" /></Link></div> 
                            {/* <div className="one noBg"><Link to={{ pathname: '/virtualSports/vbl' }}><Intl langKey="VIRTUALSPORTSMENUTITLE@BASKETBALL" /></Link></div>  */}
                            {/* <div className="one noBg"><Link to={{ pathname: '/virtualSports/vfel' }}><Intl langKey="VIRTUALSPORTSMENUTITLE@ENGLISHLEAGUE" /></Link></div>  */}
                            <div className="one"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_2}`} alt="SPECIALEVENT" /> <Link to={{ pathname: "/specialMarket" }}><Intl langKey="TITLE@SPECIALEVENT" /></Link></div>
                            <div className="one noBg"> <Link to={{ pathname: "/market/100/-1/605897/1" }}><Intl langKey="TITLE@ELECTION_2024" /></Link></div>
                            <div className="one noBg"> <Link to={{ pathname: "/market/100/-1/609209/1" }}><Intl langKey="TITLE@HARYANA_ELECTION_2024" /></Link></div>
                            {/* <div className="one noBg"><Link to={{ pathname: '/market/100/-1/353084/1' }}><Intl langKey= "TITLE@IPL_ELECTION" /></Link></div>   */}
                        </span>
                    </div>
                    <div className="flag1">
                        <span className="card">
                            <h4><Intl langKey="TITLE@MY_ACCOUNT" /></h4>
                            {/* <div className="one noBg"><img src={`${config.imageV3Host}/static/media/1.png`} /><a onClick={MyBetShowModal}><Intl langKey="TITLE@MY_BETS" /></a></div>
                        <div className="one noBg"><img src={`${config.imageV3Host}/static/media/2.png`} /><a  onClick={StatementShowModal}><Intl langKey="TITLE@ACCOUNT_STATEMENT" /></a></div>
                        <div className="one noBg"><img src={`${config.imageV3Host}/static/media/3.png`} /> <a onClick= {CasinoStatementShowModal}> <Intl langKey="TITLE@CASINO_STATEMENT" /> </a></div>  */}

                            {showDBPromoLink && <div className="one noBg"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_1}`} alt="PROMOTIONS" /><Link to="/member/promotions"><Intl langKey="TITLE@PROMOTIONS" /></Link></div>}
                           { showDBProfileLink && <div className="one noBg"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_2}`} alt="PROFILE" /><Link to="/member/profile"><Intl langKey="SIDEBAR@PROFILE" /></Link></div>}
                            {showDBFinanceLink && <div className="one noBg"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_3}`} alt="FINANCES" /> <Link to="/member/finance"> <Intl langKey="SIDEBAR@FINANCES" /> </Link></div>}
                            {showDBBetDetailLink && <div className="one noBg"><img src={`${config.imageV3Host}/static/cashsite/${theme.image.new_whitelable_footer_number_4}`} alt="BETDETAIL" /> <Link to="/member/betDetails/mybets"> <Intl langKey="SIDEBAR@BETDETAIL" /> </Link></div>}
                        </span>

                    </div>
                </FlagSection>
                <Modal show={modelState} handleClose={hideModal}>
                    <LoginContent emitModel={emitHideModel} />
                </Modal>
                <Modal show={modal} handleClose={handleClose}>
                    <SlickSliderGames modalState={modal} data={selectedGame} open={'true'} />
                </Modal>
            </FlagsWrapper>
            </LazyLoadComponent>
        </>
    )
}
export default withTheme(FlagsContainer);

export const FlagsWrapper = styled.div`
    width:100%;
    height: 100%;
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.new_whitelable_footer_banner')});
    /* background-image: url(${config.imageV3Host}/static/media/flagsFooterBgMain.jpg); */
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 5%;
    background-position: center;

    @media only screen and (min-width: 1600px){ 
        padding-bottom: 17%;
    } 
    
    // @media only screen and (max-width: 992px){ 
    //     height: 524px;
    //     background-size: contain;
    // } 
`;

export const FlagSection = styled.div`
    display: flex;
    // height: 100vh;
    width: 100%;
    // padding-left:2%;
    padding-top:12%;
    justify-content: space-between;
    padding: 17% 2%;
    padding-bottom: 0;

    .card{
        width: 100%;
        line-height:54px;  
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px 13px;
        // padding: 0em 2em;
        // padding: 0% 9%;
        
    @media only screen and (max-width: 1440px){line-height:50px;} 
    @media only screen and (max-width: 992px){line-height:35px;} 
        
        a {  
            width: 100%;
            // transform: rotate(-21deg);
            font-size:14px;
            text-transform: uppercase;
            display:flex;
            flex-direction:column;
            color: ${theme('palette.flagLinkTextColor')};
            padding: 1.5em 0em;
            padding-left: 5em;
            border-bottom: ${theme('palette.flagLinkBorder')};
            box-shadow: ${theme('palette.flagLinkBoxShadow')};
            line-height: normal;

            :hover {
                font-weight: 600;
                /* color: ${theme('palette.homeTable-background')}; */
                background:  ${theme('palette.flagsHoverBg')};;
            }

            @media only screen and (max-width: 1600px){
                font-size: 14px;
                // padding: 1em 0em;
                padding-left: 4em;
            };

            @media only screen and (max-width: 1200px){
                padding: 1em 0em;
                padding-left: 4em;
            }

            @media only screen and (max-width: 992px){
                font-size:11px;
                padding-left: 4em;
            };
        }

        h4{
            width: 100%;
            // transform: rotate(-21deg);
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            color: ${theme('palette.flagTittle')};
            line-height: normal;
            margin: 0;
            // height: 5em;
            display: flex;
            align-items: center;
            // justify-content: center;
            padding: 1em 1em 1em 3em;

            @media only screen and (max-width: 1600px){
                font-size: 16px;
            };
            @media only screen and (max-width: 992px){
                font-size: 14px;
            };
        }
    }

    .card3{
        width: 100%;
        line-height:25px;
        @media only screen and (max-width: 992px){line-height:20px;} 
    }

    .flag1{
        /* background-image: url(${config.imageS3Host}/static/snk/${theme('image.new_whitelable_footer_card')}); */
        background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.new_whitelable_footer_card')});
        background-size: cover;
        background-position: center bottom;
        // width:16%;
        width: 20%;
        height: 100%;
        max-height: 520px;
        min-height: 425px;
        background-repeat: no-repeat;
        overflow: hidden;
        transform: skew(0deg, -20deg);
        padding-top: 0;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        @media only screen and (max-width: 1200px){
            background-size: contain;
            background-position: center top;
            min-height: 485px;
        }
    }
    
    .one{
        width:100%;
        // margin-top:10px;
        display:flex;
        position: relative;
        align-items: center;
        justify-content: flex-start;
        background: ${theme('palette.flagsHoverBg')};
    

        a {
            
        }

        img{
            /* width: 20px;
            height: 26px; */
            width: 17px;
            height: 26px;
            // margin-top:10px;
            position: absolute;
            // top: 18px;
            left: 35px;
            // height:60%;

            @media only screen and (max-width: 1600px){
                left: 24px;
            };

            @media only screen and (max-width: 992px){
                width: 17px;
                height: 17px;
                // top: 11px;
                left: 15px;
            }
        }
    }

    .one.noBg {
        background: ${theme('palette.flagLinkNoBg')};
    }
`;
const Wrapper3 = styled.div`
    width: 100%;
    height: 79px;
    padding-bottom: 1px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.comePlayBg')});
    background-repeat: no-repeat;
    background-size: cover;

    @media only screen and (max-width: 992px){
        background-size:cover;
        height: 55px; 
    }

    h3 {
        display: flex;
        color: ${theme('palette.homeTittleTexts')};
        text-transform: uppercase;
        font-size: 30px;
        letter-spacing: 0.05em;
        margin: 0;
        line-height: 37px;

        @media only screen and (max-width: 992px){
            font-size: 25px; 
        }

        @media only screen and (min-width: 1800px){
            font-size: 35px;
        }

    }

`;  