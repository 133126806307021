import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useLocation } from "react-router-dom";
import Tabs from "../../common/Tab";
// Imported Variables
import { color } from "../../../sass-files/variable";
import { GET_HOT_EVENTS } from "../../../graphql/events";
//imported Components
import Fancytable from "./fancy-table";
import MoreOddstable from "./moreodds-table";
import PopularBookmakerTable from "./bookmaker-table";
import { useQuery } from "@apollo/client";
import EventTable from "../../eu-content/table/feature/EventTable";
// Util
import util from "../../../util/util";
import { withAppContext } from "src/store/initAppContext";
import moment from "moment";
import Intl from "../../common/Intl";
import { theme } from "styled-tools";
import { ECookieName } from "src/util/utilModel";
import cookieUtil from "src/util/cookieUtil";

const { yellowfont } = color;
// const {secondarycolor} = color
// const tabName = ['Today', 'Tomorrow', 'Upcoming']
let Today = <Intl langKey="TABLE@TODAY" />;
let Tomorrow = <Intl langKey="TABLE@TOMORROW" />;
let Upcoming = <Intl langKey="TABLE@UPCOMING" />;

const tabName = [Today, Tomorrow, Upcoming];

function PopularTableBodyContent(props) {
  const [inpleaystate, setInpleaystate] = useState([]);
  const [catId, setCatId] = useState(0)
  let location = useLocation();
  let { pathname } = location;
  let urlLength = pathname.lastIndexOf(":");
  let categoryIdString = pathname.substring(urlLength + 1);
  let _pollInterval = cookieUtil.get(ECookieName.COOKIE_TOKEN) ? 5000 : 50000;
  const { loading, error, data } = useQuery(GET_HOT_EVENTS, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        categoryId: catId,
        isInplay: true,
        singleFancy: true,
      },
    },
    pollInterval: _pollInterval,
  });
  const returnMax = (arr) => {
    return arr.sort((a, b) => b.price - a.price)[0];
  };

  useEffect(() => {
    let list = data;
    if (list) {
      const result = util.getValue(list, ["markets", "hot"]);
      // setInpleaystate(result);
    }
  }, []);

  useEffect(() => {
    if(pathname.includes('/betting-exchange/volleyball-betting')){
        setCatId(12);
    } else if (pathname.includes('/betting-exchange/online-cricket-satta-bazar')){
        setCatId(4);
    } 
    else if (pathname.includes('/betting-exchange/online-cricket-betting')){
      setCatId(4);
   }
    else if (pathname.includes('/betting-exchange/tennis-betting')) {
        setCatId(2);
    } else if (pathname.includes('/betting-exchange/football-betting')) {
        setCatId(1);
    } else {
        setCatId(0);
    }
},[pathname])


  if (loading) {
    return <Loading>Loading ...</Loading>;
  }

    const gameData = util.getValue(data, ['markets', 'hot']);
    const cricketData = (pathname.includes('betting-exchange/online-cricket-satta-bazar')|| pathname.includes('betting-exchange/online-cricket-betting')) && gameData?.filter(val => { return val.categoryId == 4 }) ||
        (pathname.includes('betting-exchange/tennis-betting')) && gameData?.filter(val => { return val.categoryId == 2 }) ||
        (pathname.includes('betting-exchange/football-betting')) && gameData?.filter(val => { return val.categoryId == 1 }) ||
        (pathname.includes('betting-exchange/volleyball-betting')) && gameData?.filter(val => { return val.categoryId == 12 }) ||
        (pathname.includes('sports/:0')) && gameData || gameData;
    const today = moment();
    const tomorrow = moment().add(1, 'day');
    const todayData  = cricketData && cricketData.filter(
        cricketData =>
        today.isSame(cricketData.openDate, 'day')
      );
    const tomorrowData = cricketData && cricketData.filter(
        cricketData =>
        tomorrow.isSame(cricketData.openDate, 'day')
    );
    const upcomingData = cricketData && cricketData.filter(
        cricketData => !today.isSame(cricketData.openDate, 'day') && !tomorrow.isSame(cricketData.openDate, 'day')
    )


  return (
    <>
      <Tabs
        tabs={tabName}
        isTabsHidden={false}
        minWidthInContent={"initial"}
        render={(index) => {
          return (
            <>
              {tabName[index] === Today && (
                <EventTable homePage={true} app={props} dataList={todayData} />
              )}
              {tabName[index] === Tomorrow && (
                <EventTable
                  homePage={true}
                  app={props}
                  dataList={tomorrowData}
                />
              )}
              {tabName[index] === Upcoming && (
                <EventTable
                  homePage={true}
                  app={props}
                  dataList={upcomingData}
                />
              )}
            </>
          );
        }}
      />
    </>
  );
}

export default withAppContext(PopularTableBodyContent);

const TableBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #ebebeb;
  position: relative;

  .viewmore {
    position: absolute;
    bottom: -22px;
    right: 0px;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 11px;
    color: #203974;
    letter-spacing: 0.1em;

    cursor: pointer;
    z-index: 99;

    :hover {
      color: ${yellowfont};
    }
  }
`;

const Loading = styled.div`
  color: ${theme("palette.Loading-color")};
`;
